// src/components/Message.tsx
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { MessageDto } from "../models/MessageDto";
import MarkdownIt from "markdown-it";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faRobot } from "@fortawesome/free-solid-svg-icons";
import {
  Box,
  Typography,
  Paper,
  Chip,
  Grid,
  AlertColor,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import hljs from "highlight.js";
import "highlight.js/styles/default.css"; // O el tema que prefieras
import Loader from "./Loader";

import {
  Agriculture,
  CheckCircle,
  DoneOutline,
  EnergySavingsLeaf,
  Spa,
} from "@mui/icons-material";
import {
  HourglassEmpty,
  Autorenew,
  Android,
  HelpOutline,
} from "@mui/icons-material";

import "./Message.css";
import { Link } from "@mui/material";
import Message from "./Message";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import useFetchAPI from "hooks/useFetchApi";
import moment from "moment-timezone";
import { useAppContext } from "../hooks/useAppContext";
import { useConversacionContext } from "../hooks/useConversacionContext";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Text } from "./Text";

interface MessageProps {
  messages: any;
  lastGroup?: any;
  lastMessage?: MessageDto;
  conversationId: string;
  isBuildingPanel?: boolean;
  isWaiting?: boolean;
  buildingToolState?: number;
  runStepError?: boolean | string;
  assistant?: any;
}

/**
 * Recibe un listado de mensajes del mismo rol (chat o usuario) y los muestra agrupados
 *
 * Props:
 * - messages: MessageDto[] - The messages to be displayed.
 * - conversationId: string - The ID of the conversation the messages belong to.
 *
 * This component is used to display a panel of messages in the user interface.
 * @param messages
 * @param conversationId
 * @param lastMessage
 * @param assistant
 * @constructor
 * MessagesPanel Component
 */
const MessagesPanel: React.FC<MessageProps> = (props) => {
  const {
    messages,
    lastGroup,
    conversationId,
    isBuildingPanel,
    assistant,
    buildingToolState,
    runStepError,
    isWaiting,
  } = props;
  const isUser = messages[0].isUser;
  const [open, setOpen] = React.useState(false);
  const [infoMensajeLoading, setInfoMensajeLoading] = React.useState(false);
  const [infoMensaje, setInfoMensaje] = React.useState<any>(null);
  const [toolCall, setToolCall] = React.useState<any>(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const fetchAPI = useFetchAPI();

  const handleOpen = async () => {
    if (!conversationId || !lastGroup) {
      return;
    }

    setInfoMensajeLoading(true);
    setOpen(true);

    let mensajes = [];
    let mensajeId = null;
    if (lastGroup[lastGroup.length - 1] && lastGroup[lastGroup.length - 1].id) {
      mensajeId = `${lastGroup[lastGroup.length - 1].id}`;
    }

    if (!mensajeId) {
      console.log("no hay nada para ver");
      setInfoMensajeLoading(false);
      return;
    }

    let response = await fetchAPI(
      `/conversations/${conversationId}/messages/${mensajeId}`
    );

    if (response) {
      setInfoMensaje(response);
    }

    setInfoMensajeLoading(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const obtenerFechaCreacionPorTimezone = (message: MessageDto) => {
    if (!message.fecha_creacion) {
      return "";
    }

    const timestampInMilliseconds = Number(message.fecha_creacion) * 1000;
    const date = moment.tz(
      timestampInMilliseconds,
      "America/Argentina/Buenos_Aires"
    );
    return date.format("DD/MM/YYYY HH:mm");
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        textAlign: "left",
      }}
    >
      {/* Se agrega un div flexbox para que se pueda tener un espaciado entre los iconos y las respuestas en si*/}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
        }}
      >
        <div>
          <Box
            display={"inline-flex"}
            flexDirection={"row"}
            style={{
              paddingTop: "15px",
              color: "black",
              width: "fit-content",
            }}
          >
            {isUser ? (
              <FontAwesomeIcon
                icon={faUser}
                title={"#"}
                style={{
                  backgroundColor: theme.palette.secondary.main,
                  borderRadius: "100%",
                  height: "1.25em",
                  width: "1.25em",
                  padding: "0.5em",
                  color: "#FFFFFF",
                }}
              />
            ) : (
              <FontAwesomeIcon
                icon={faRobot}
                title={"#"}
                style={{
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: "100%",
                  height: "1.25em",
                  width: "1.25em",
                  padding: "0.5em",
                  color: "#FFFFFF",
                }}
                onClick={() => {
                  handleOpen();
                }}
              />
            )}
          </Box>
        </div>
        <div
          style={{
            flex: 11,
            padding: "15px 15px 0",
            borderRadius: "8px",
            marginLeft: "0px",
            marginTop: "5px",
            marginRight: "0",
            marginBottom: "0",
          }}
        >
          <div>
            {isUser ? (
              <Box
                display={"inline-flex"}
                flexDirection={"row"}
                style={{
                  color: "black",
                  width: "fit-content",
                }}
              >
                <Typography
                  fontWeight="bold"
                  fontFamily="Work Sans"
                  fontSize="20px"
                  lineHeight="-2%"
                  title={
                    messages && messages.length > 0
                      ? obtenerFechaCreacionPorTimezone(messages[0])
                      : ""
                  }
                  sx={{ margin: "auto" }}
                >
                  Vos
                </Typography>
              </Box>
            ) : (
              <Box
                display={"inline-flex"}
                flexDirection={"row"}
                style={{
                  color: "black",
                  width: "fit-content",
                }}
              >
                <Typography
                  fontWeight="bold"
                  fontFamily="Work Sans"
                  fontSize="20px"
                  lineHeight="-2%"
                  title={
                    messages && messages.length > 0
                      ? obtenerFechaCreacionPorTimezone(messages[0])
                      : ""
                  }
                  sx={{ margin: "auto" }}
                >
                  {assistant.nombre}
                </Typography>

                {isBuildingPanel && (
                  <CircularProgress
                    color="inherit"
                    size={18}
                    sx={{ ml: "12px", mt: "4px" }}
                  />
                )}
              </Box>
            )}
          </div>
        </div>
      </div>

      <div>
        <div style={{ paddingLeft: isMobile ? 0 : "50px" }}>
          {messages.map((message, index) => {
            let lastMessage = index > 0 ? messages[index - 1] : null;
            return (
              <Message
                key={index}
                message={message}
                lastMessage={null}
                conversationId={conversationId}
                isBuildingPanel={isBuildingPanel}
              />
            );
          })}

          {isBuildingPanel && buildingToolState > 0 && (
            <Box display={"flex"} flexDirection={"column"} width="100%">
              <FunctionCallingBox estado={buildingToolState} />
            </Box>
          )}

          {isBuildingPanel && runStepError && (
            <Box display={"flex"} flexDirection={"column"} width="100%">
              <Alert severity="error" variant="filled" icon={false}>
                <Stack direction="row" alignItems="center" gap="8px">
                  <Box>
                    <HelpOutline />
                  </Box>
                  <Text variant="p3">{runStepError}</Text>
                </Stack>
              </Alert>

              {!isWaiting ? (
                <Link
                  sx={{ marginTop: 2 }}
                  onClick={() => {
                    alert("No implementado");
                  }}
                  style={{ marginTop: "10px", fontSize: "0.9rem" }}
                  href={"#"}
                >
                  <Text variant="p4">Volver a intentar</Text>
                </Link>
              ) : (
                <Text variant="p4">
                  Vamos a volver a intentarlo. Danos unos segundos.
                </Text>
              )}
            </Box>
          )}

          {/*@todo: Agregar loading para tool call y procesamiento */}
          {/*@todo: Agregar loader general entretenido */}

          {/*<Link onClick={verPasosIntermedios} style={{marginTop: "10px", fontSize: "0.9rem"}} href={"#"}>Ver pasos intermedios</Link>*/}
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 800,
            bgcolor: "background.paper",
            border: "1px solid #fafaf8",
            p: 2,
          }}
        >
          {infoMensajeLoading || !infoMensaje ? (
            <Loader notFullHeight={true} />
          ) : (
            <VisorPasosEjecucion infoMensaje={infoMensaje} />
          )}
        </Box>
      </Modal>
    </div>
  );
};

function FunctionCallingBox(props: { estado: number }) {
  const { estado } = props;
  const [severity, setSeverity] = React.useState("info");
  const [index, setIndex] = React.useState(0);
  const [opacity, setOpacity] = React.useState(1);
  const theme = useTheme();
  const { accountConfig } = useAppContext();

  const mensajesLoading = useMemo(() => {
    if (!accountConfig || !accountConfig.messagesPanel?.loadingMessages) {
      return [
        "<strong>Ejecutando función</strong> Solo un poco más de paciencia.",
      ];
    }

    return accountConfig.messagesPanel.loadingMessages;
  }, [accountConfig]);

  const icons = useMemo(() => {
    if (!accountConfig || !accountConfig.messagesPanel?.loadingIcons) {
      return [<Android />];
    }

    return accountConfig.messagesPanel.loadingIcons;
  }, [accountConfig]);

  useEffect(() => {
    setIndex(Math.floor(Math.random() * mensajesLoading.length));
  }, []);

  useEffect(() => {
    if (estado === 1) {
      const timer = setInterval(() => {
        setOpacity(0);
        setTimeout(() => {
          setIndex((prevIndex) => (prevIndex + 1) % mensajesLoading.length);
          setOpacity(1);
        }, 500);
      }, 10000);
      return () => clearInterval(timer);
    }
  }, [estado]);
  return (
    <Box flex={1} my={2}>
      <Alert
        severity={estado === 1 ? "info" : "success"}
        variant="filled"
        icon={false}
        sx={{
          backgroundColor: estado === 1 ? "transparent" : undefined,
          color: estado === 1 ? theme.palette.primary.main : undefined,
          border:
            estado === 1
              ? "1px solid " + theme.palette.primary.main
              : undefined,
        }}
      >
        <Box display="flex" alignItems="center">
          <Box style={{ transition: "opacity 0.5s", opacity: opacity }}>
            {estado === 1 ? icons[index] : <CheckCircle />}
          </Box>
          <Typography
            mr={1}
            ml={1}
            style={{
              transition: "opacity 0.5s",
              opacity: opacity,
              fontSize: "95%",
            }}
          >
            {estado === 1 ? (
              <span
                dangerouslySetInnerHTML={{ __html: mensajesLoading[index] }}
              ></span>
            ) : (
              "Función ejecutada correctamente"
            )}
          </Typography>
        </Box>
      </Alert>
    </Box>
  );
}

type PasosEjecucionProps = {
  infoMensaje: any;
};

const VisorPasosEjecucion = (props: PasosEjecucionProps) => {
  const { infoMensaje } = props;
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Box
      sx={{
        width: "90%",
        maxWidth: 800,
        bgcolor: "background.paper",
        p: 2,
        my: 1,
      }}
      className={"detalle_step"}
    >
      <Typography variant="h5" gutterBottom>
        Run: {infoMensaje["message"]["run_id"]}
      </Typography>
      <Typography variant="h6" gutterBottom>
        Steps
      </Typography>
      {infoMensaje["run"]["steps"]
        .slice()
        .reverse()
        .map((step: any, index: number) => (
          <Accordion
            key={"step_" + index}
            expanded={expanded === "panel" + index}
            onChange={handleChange("panel" + index)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={"panel" + index + "bh-content"}
              id={"panel" + index + "bh-header"}
            >
              <Typography sx={{ flexShrink: 0 }}>
                <Typography fontWeight={"bold"} display={"inline"}>
                  {" "}
                  {index + 1}. {step["type"]}
                </Typography>
                {step["details"][0] && step["details"][0]["type"] && (
                  <span> - {step["details"][0]["type"]}</span>
                )}
                : {step["id"]}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {step["details"].map((detail: any, detailIndex: number) => (
                <Box sx={{ mt: 1 }} key={"detail_" + detailIndex}>
                  <Typography variant="body1">
                    {detail["type"]}: #{detail["id"]}
                  </Typography>
                  {["code_interpreter", "function"].includes(
                    detail["type"]
                  ) && (
                    <Box>
                      <Box
                        component="pre"
                        sx={{
                          display: "block",
                          p: 1,
                          bgcolor: "grey.200",
                          overflow: "auto",
                          maxHeight: 120,
                        }}
                      >
                        {detail["input"]}
                      </Box>
                      <Box
                        component="pre"
                        sx={{
                          display: "block",
                          p: 1,
                          bgcolor: "grey.200",
                          overflow: "auto",
                          maxHeight: 120,
                          mt: 1,
                        }}
                      >
                        {detail["outputs"]}
                      </Box>
                    </Box>
                  )}
                </Box>
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
    </Box>
  );
};

export default MessagesPanel;
