import {useEffect, useMemo, useState} from 'react';
import io from 'socket.io-client';
import {useConversacionContext} from "../useConversacionContext";
import {MessageDto} from "../../models/MessageDto";

const useSocket = () => {

    const {
        mensajeEnConstruccion, setMensajeEnConstruccion,
        actualizarMensajeEnConstruccion, actualizarArchivosMensajeEnConstruccion,
        setNuevoMensajeToolCall, setNuevoMensajeRunStepError
    } = useConversacionContext();

    const miSocketClientId = useMemo(() => {
        return window.localStorage.getItem('llm_token')
    }, []);

    const socket = useMemo(() => {
        // @todo: agregar que si es openai assistant y tiene stream vale! por ahora hardcodeamos
        // if (!assistant) {
        //     return null;
        // }
        const apiUrl = process.env.REACT_APP_SOCKET_IO_URL;
        return io(apiUrl, {
            query: {
                // token: window.localStorage.getItem('llm_token'), // Agregar token u otra info única si es necesario
                clientId: miSocketClientId  // Identificador único para cada cliente
            }
        })
    }, []);

    const [socketConectado, setSocketConectado] = useState(socket ? socket.connected : false);

    useEffect(() => {
        const socketInstance = socket;
        const eventHandlers = {
            connect: () => {
                //console.log("conectado");
                socket.emit('join_room', { clientId: miSocketClientId });
                setSocketConectado(true);
            },
            disconnect: () => {
                //console.log("desconectado");
                setSocketConectado(false);
            },
            message: (data) => {
                console.log("on_message", data);
            },
            on_event: (data) => {
                //console.log("socketio: evento disparado", data.event);
                // if (data.event === "thread.run.completed") {
                //     setNuevoMensajeToolCall(0);
                // }
            },
            on_run_step_created: (value) => {
                // console.log("socketio: run step created", value);

                // cuando vuelvo a correr un paso limpio el error
                setTimeout(() => {setNuevoMensajeRunStepError(false);}, 1000);
                
                if (value.type === "tool_calls") {
                    setNuevoMensajeToolCall(1);
                }
            },
            on_run_step_done: (value) => {
                console.log("socketio: run step done", value);
                if (value.type === "tool_calls") {
                    setNuevoMensajeToolCall(0);
                }

                if (value.status === "failed") {
                    //alert("hubo un problema con openai");
                    console.log(value.last_error);
                    setNuevoMensajeRunStepError(value.last_error.message)
                }
            },
            on_message_created: (value) => {
                // console.log("socketio: message created", value);
                // creo el mensaje en construccion
                // prueba para testear el stream
                //let nuevoMensaje = createNewMessage("xxxx-xxxxx", "", false, []);
                //setMensajeEnConstruccion(nuevoMensaje);
            },
            on_message_done: (value) => {
                // console.log("socketio: message done", value);
                // if (value.content) {

                // }

                // agrego dos espacios para mensaje y mensajes
                actualizarMensajeEnConstruccion({"data": "\n\n"});
            },
            on_text_delta: (value) => {
                //console.log("socketio: on_text_delta", value);
                actualizarMensajeEnConstruccion(value);
            },
            on_image_delta: (value) => {
                //console.log("socketio: on_image_delta", value);
            },
            on_image_file_done: (value) => {
                //console.log("socketio: on_image_file_done", value);
                //actualizarArchivosMensajeEnConstruccion(value);
            },
            on_tool_call_created: (value) => {
                //console.log("socketio: tool call created", value);

            },
            on_tool_call_delta: (value) => {
                // console.log("socketio: tool call delta", value);
                // por ahora aca no hago nada
            },
            on_tool_call_done: (value) => {
            },
            finish: (value) => {
                // console.log(value);
                // refrescarListaDeMensajes(conversationId);
                //setMensajeEnConstruccion(null);
            }
        };

        Object.entries(eventHandlers).forEach(([event, handler]) => {
            socket.on(event, handler);
        });

        return () => {
            Object.keys(eventHandlers).forEach((event) => {
                socket.off(event, eventHandlers[event]);
            });
        };
    }, []);

    return socket;
};

export default useSocket;