
export class MessageDto {
    isUser: boolean;
    id: string;
    content: string;
    files: string[];
    fecha_creacion?: string;

    constructor(id: string, isUser: boolean, content: string, files?: string[], fecha_creacion?: string) {
        this.id = id;
        this.isUser = isUser;
        this.content = content;
        this.files = files;
        this.fecha_creacion = fecha_creacion;
    }

}
