// useFetchAPI.ts
import {useCallback, useContext, useState} from 'react';
import {useAppContext} from "./useAppContext";

class ApiError extends Error {
    statusCode: number; // Define explícitamente la propiedad statusCode

    constructor(message: string, statusCode: number) {
        super(message); // Llama al constructor de la clase Error
        this.statusCode = statusCode; // Inicializa la propiedad statusCode
        this.name = "ApiError"; // Personaliza el nombre del error
    }
}


const useFetchAPI = () => {

    const {
        tokenSistema,
        tokenSistemaValido, setTokenSistemaValido,
        apiAccesible, setApiAccesible
    } = useAppContext();


    const fetchAPI = useCallback(async (endpoint: string, method: string = 'GET', body?: any, useToken: boolean = true, defaultHeaders = {}, appendUrl = true) => {
        let fallbackToken = null;
        let tokenAutenticacion = tokenSistema;
        if (useToken && !tokenSistema) {
            fallbackToken = window.localStorage.getItem('llm_token');
            // console.log("Fallback token:", fallbackToken);
            if (!fallbackToken) {
                return false;
            }

            tokenAutenticacion = fallbackToken;
        }

        const apiUrl = process.env.REACT_APP_API_URL;
        const headers: HeadersInit = useToken ? {
            'Authorization': `Bearer ${tokenAutenticacion}`,
            //'Content-Type': 'application/json',
        } : {};

        Object.assign(headers, defaultHeaders);

        const config: RequestInit = {
            method,
            headers,
            credentials: 'include',
            ...(body && { body: body }),
        };

        try {
            let fullApiUrl = appendUrl ? apiUrl + endpoint : endpoint;
            const response = await fetch(fullApiUrl, config);
            const data = await response.json();

            // Revisa si el status es "error"
            if (!response.ok) {
                if (data && data.status === "error") {
                    throw new ApiError(data.error, response.status);
                } else {
                    throw new ApiError(`API request failed: ${response.statusText}`, response.status);
                }
            }

            return data;
        } catch (error) {
            // si el error tiene que ver con autenticacion y que falla la autenticacion lo capturo aca
            // ya que puedo gestionarlo a nivel app integral.
            if (error.statusCode === 401) {
                // @todo: tengo que vaciar el token y mostrar pantalla de error
                if (error.message == "Token expirado.") {
                    window.localStorage.removeItem("llm_token");
                    return null;
                }
                
            } else if (error.statusCode === 403) {
                // no tenes permisos para acceder a este recurso
                alert(error.message);
            }

            throw error;
            return null; // Considera devolver null o lanzar el error dependiendo de tu enfoque de manejo de errores
        }
    }, [tokenSistema]);

    return fetchAPI;
};

export default useFetchAPI;
