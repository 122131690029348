import {
  Button,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  Typography,
  Box,
  IconButton,
  Stack,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz"; // Import the SwapHoriz icon
import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { differenceInCalendarDays } from "date-fns";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import "./Sidebar.css";
import { Helmet } from "react-helmet";
import { useAppContext } from "hooks/useAppContext";
import { Conversacion } from "../types/Conversacion";
import useConversaciones from "../hooks/useConversaciones";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import { Text } from "./Text";

type SidebarProps = {
  seleccionarConversacion: CallableFunction;
  anchor: "right";
  open: boolean;
  handleDrawerToggle: () => void;
  resetSelectAccount: CallableFunction;
  actualizarSidebar: CallableFunction;
  mostrarSwitcher?: boolean;
};

/**
 * Sidebar Component
 *
 * Props:
 * - conversaciones: any[] - The conversations to be displayed.
 * - conversacionSeleccionada: string - The ID of the selected conversation.
 * - seleccionarConversacion: CallableFunction - Function to select a conversation.
 * - open: boolean - State to manage the opening and closing of the sidebar.
 * - handleDrawerToggle: () => void - Function to handle the opening and closing of the sidebar.
 *
 * This component is used to display a sidebar of conversations in the user interface.
 */
const Sidebar = (props: SidebarProps) => {
  const {
    seleccionarConversacion,
    open,
    handleDrawerToggle,
    resetSelectAccount,
    actualizarSidebar,
    mostrarSwitcher,
  } = props;

  const {
    conversaciones,
    conversacionSeleccionada,
    setConversacionSeleccionada,
    cuentaSeleccionada,
    usuario,
    idConversacion,
    setIdConversacion,
    accountConfig,
  } = useAppContext();

  const drawerWidth = 350;
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  /**
   * Agrupa conversaciones por fecha.
   * Queda revisar si se sigue usando porque se favoreció el hook useConversaciones.
   */
  const groupConversationsByDate = useCallback((conversations) => {
    const groups = {
      Hoy: [],
      Ayer: [],
      "Ultimos 7 días": [],
      "Ultimos 30 días": [],
      Anterior: [],
    };

    const now = new Date();

    if (!conversations || conversations.length == 0) {
      return [];
    }

    conversations.forEach((conversation) => {
      const conversationDate = new Date(conversation.fecha_ultimo_mensaje);
      const diffInDays = differenceInCalendarDays(now, conversationDate);

      if (diffInDays === 0) {
        groups["Hoy"].push(conversation);
      } else if (diffInDays === 1) {
        groups["Ayer"].push(conversation);
      } else if (diffInDays <= 7) {
        groups["Ultimos 7 días"].push(conversation);
      } else if (diffInDays <= 30) {
        groups["Ultimos 30 días"].push(conversation);
      } else {
        groups["Anterior"].push(conversation);
      }
    });

    return groups;
  }, []);

  /**
   * Memo de la agrupación de las conversaciones para evitar renderizados innecesarios.
   */
  const groupedConversations = useMemo(
    () => groupConversationsByDate(conversaciones),
    [conversaciones]
  );

  /**
   * Levanta la variante segun la configuración de la cuenta
   */
  const obtenerVariante = useCallback(() => {
    // console.log("account config", accountConfig);
    if (isMobile) {
      return "temporary";
    } else {
      return accountConfig && accountConfig.sidebar
        ? accountConfig.sidebar.variant
        : "permanent";
    }
  }, [accountConfig]);

  const obtenerAnchor = useCallback(() => {
    return accountConfig && accountConfig.sidebar
      ? accountConfig.sidebar.anchor
      : "right";
  }, [accountConfig]);

  // Si no hay cuenta seleccionada, no renderiza el sidebar
  if (!cuentaSeleccionada) {
    return;
  }

  return (
    <div
      style={{
        order:
          accountConfig?.sidebar && accountConfig.sidebar.anchor === "left"
            ? 1
            : 2,
      }}
    >
      <Drawer
        variant={obtenerVariante()}
        open={open}
        anchor={obtenerAnchor()}
        onClose={handleDrawerToggle}
        style={{ width: drawerWidth, padding: "5px 5px" }}
        className={"sidebar"}
        PaperProps={{
          style: {
            width: drawerWidth,
            padding: "0",
            backgroundColor: theme.palette.backgroundSidebar.main,
            borderRight: "none",
            display: "flex",
            flexDirection: "row",
            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0)",
          },
        }}
      >
        {obtenerVariante() === "temporary" && (
          <IconButton
            id="menuButton"
            color="default"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            //sx={{marginLeft: "auto", display: {sm: 1}}}
            sx={{
              mt: 1,
              display: { sm: 1 },
              color: "white",
              position: "fixed",
              top: isMobile ? "1rem" : "3rem",
              marginLeft: "0px",
              right: obtenerAnchor() === "right" ? "350px" : "auto", // Ajusta a tu gusto
              left: obtenerAnchor() === "left" ? "350px" : "auto",
              borderRadius: "0",
              backgroundColor: "black",
              height: "1.5em",
              width: "1.5em",
            }}
          >
            <MenuOpenIcon style={{ transform: "scaleX(-1)" }} />
          </IconButton>
        )}

        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          width="100%"
          style={{
            backgroundColor: theme.palette.backgroundSidebar.main,
            padding: "0px 10px",
            overflowY: "auto",
          }}
        >
          <Helmet>
            <title>{cuentaSeleccionada.html_title}</title>
            {cuentaSeleccionada.favicon_logo && (
              <link
                rel="icon"
                href={"/images/" + cuentaSeleccionada.favicon_logo}
              />
            )}
            <meta name="description" content="-" />
          </Helmet>
          <Box style={{ marginTop: "1em" }}>
            {cuentaSeleccionada.logo && (
              <Box textAlign={"center"}>
                <img
                  src={"/images/" + cuentaSeleccionada.logo}
                  style={{ maxWidth: "80%" }}
                  alt="logo"
                />
              </Box>
            )}

            <Box textAlign={"center"} style={{ padding: "0 15px" }}>
              <Button
                fullWidth
                endIcon={<FontAwesomeIcon icon={faPlus} />}
                sx={{
                  margin: "10px 0",
                  padding: "10px 5px",
                  backgroundColor: theme.palette.primary.main,
                  color: "white",
                }}
                variant={"contained"}
                onClick={() => {
                  setConversacionSeleccionada(null);
                  setIdConversacion(null);
                  navigate("/nueva-conversacion");
                  handleDrawerToggle();
                }}
              >
                <Text variant="h6" style={{ textTransform: "uppercase" }}>
                  Nueva conversación
                </Text>
              </Button>
            </Box>

            {conversaciones && conversaciones.length > 0 ? (
              Object.keys(groupedConversations).map((group) => {
                if (groupedConversations[group].length > 0) {
                  return (
                    <div key={group}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        padding="0 15px"
                        margin="10px 0px 10px"
                        color="darkgrey"
                      >
                        <ChatBubbleOutlineOutlinedIcon
                          style={{
                            paddingRight: "5px",
                            height: "16px",
                            width: "16px",
                          }}
                        />
                        <Text
                          variant="p3"
                          style={{ textTransform: "uppercase" }}
                        >
                          {group}
                        </Text>
                      </Stack>

                      <List
                        style={{
                          marginLeft: "1em",
                          marginRight: "1em",
                          paddingTop: 0,
                        }}
                      >
                        {groupedConversations[group].map(
                          (conversation, index) => {
                            return (
                              <ListItemWithButtons
                                key={conversation.id}
                                conversacionSeleccionada={
                                  conversacionSeleccionada
                                }
                                navigate={navigate}
                                setConversacionSeleccionada={
                                  setConversacionSeleccionada
                                }
                                conversation={conversation}
                                posicion={index}
                              />
                            );
                          }
                        )}
                      </List>
                    </div>
                  );
                }
                return null;
              })
            ) : (
              <Box m="16px">
                <Text variant="p3">No tenés conversaciones</Text>
              </Box>
            )}
          </Box>
        </Box>
      </Drawer>

      {obtenerVariante() === "temporary" && (
        <IconButton
          color="default"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mt: 1, display: { sm: 1 } }}
          style={{
            color: "white",
            position: "fixed", // Cambia 'relative' a 'fixed'
            top: isMobile ? "1rem" : "3rem",
            right: obtenerAnchor() === "right" ? 0 : "auto", // Ajusta a tu gusto
            left: obtenerAnchor() === "left" ? 0 : "auto",
            borderRadius: "0",
            backgroundColor: "black",
            height: "1.5em",
            width: "1.5em",
            marginLeft: obtenerAnchor() === "right" ? "20px" : 0, // Ajusta a tu gusto
          }}
        >
          <MenuIcon />
        </IconButton>
      )}
    </div>
  );
};

const ListItemWithButtons = ({
  conversation,
  setConversacionSeleccionada,
  conversacionSeleccionada,
  navigate,
  posicion,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState<string>(conversation.titulo);
  const {
    eliminarConversacion,
    actualizarConversaciones,
    actualizarTituloConversacion,
  } = useConversaciones();
  const inputRef = useRef(null); // Agrega esta línea

  const handleTitleChange = (e) => {
    setEditedTitle(e.target.value);
  };

  const handleTitleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.stopPropagation();
      actualizarTituloConversacion(conversation.id, editedTitle);
      setIsEditing(false);
    }
  };

  const eliminarConversacionMenu = async () => {
    if (eliminarConversacion(conversation.id)) {
      actualizarConversaciones();
      // si es justo la que estamos eliminando, navegamos a home
      if (conversation.id === conversacionSeleccionada.id) {
        navigate("/");
      }
    } else {
      alert("no se puede eliminar el alert");
    }
  };

  const handleEditClick = (e) => {
    e.stopPropagation();
    setIsEditing(true);
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 250);
  };

  const selected = conversacionSeleccionada
    ? conversation.id === conversacionSeleccionada.id
    : false;

  return (
    <ListItemButton
      key={conversation.id}
      selected={selected}
      onClick={
        !isEditing
          ? () => {
              setConversacionSeleccionada(conversation);
              navigate("/ver-conversacion/" + conversation.id);
              document.getElementById("menuButton")?.click();
            }
          : () => {}
      }
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{ overflow: "hidden" }}
    >
      {isEditing ? (
        <input
          value={editedTitle}
          onChange={handleTitleChange}
          onKeyDown={handleTitleKeyDown}
          ref={inputRef}
          style={{
            padding: "5px",
            fontSize: "14px",
            fontFamily: "Work Sans",
          }}
        />
      ) : (
        <Text
          variant="p3"
          style={{
            margin: "5px 0",
            padding: "0",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {conversation.titulo ? conversation.titulo : "Sin título"}
        </Text>
      )}
      {isHovered && (
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            left: 0,
            right: 0,
            background: selected
              ? "linear-gradient(87deg, rgba(0,0,0,0) 65%, rgba(72,187,135,1) 73%)"
              : "linear-gradient(87deg, rgba(0,0,0,0) 65%, #F0F0EE 73%)",
          }}
        >
          <Stack direction="row" justifyContent="flex-end" mr="16px">
            <IconButton
              edge="end"
              size={"medium"}
              aria-label="edit"
              sx={{
                fontSize: 24,
              }}
              disableRipple
              disableTouchRipple
              disableFocusRipple
              onClick={handleEditClick}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              edge="end"
              size={"medium"}
              aria-label="delete"
              sx={{
                fontSize: 24,
              }}
              disableRipple
              disableTouchRipple
              disableFocusRipple
              onClick={(e) => {
                e.stopPropagation();
                if (window.confirm("Está seguro?")) {
                  eliminarConversacionMenu();
                }

                return false;
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
        </Box>
      )}
    </ListItemButton>
  );
};

export default Sidebar;
