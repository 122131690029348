import {useNavigate} from "react-router-dom";
import React, {useEffect} from "react";
import {Box} from "@mui/material";
import Alert from "@mui/material/Alert";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import Chat from "./Chat";
import {useAppContext} from "../hooks/useAppContext";

type HomeProps = {
}

/**
 * Home Component
 *
 * Props:
 * - conversaciones: any[] - The conversations to be displayed.
 * - conversacionSeleccionada: string - The selected conversation.
 * - seleccionarConversacion: CallableFunction - Function to select a conversation.
 *
 * Methods:
 * - useEffect: React Hook to perform side effects in function components.
 * - navigate: Function to navigate to a different route.
 */
const Home = (props: HomeProps)  => {

    const { conversaciones, asistenteSeleccionado, cuentaSeleccionada } = useAppContext();

    const navigate = useNavigate();

    useEffect(() => {
        if (!conversaciones || conversaciones.length == 0) {
            navigate("/nueva-conversacion");
            return;
        }

        navigate("/ver-conversacion/"+conversaciones[0]["id"]);
    }, [cuentaSeleccionada, conversaciones]);

    return (
        //Por alguna razon si borro este componente entero o retorno null no funciona y se rompe el backend, revisar luego
        <Box display="flex" justifyContent="center" alignItems="center" width={"100%"}>
            <Alert severity="info" icon={<FontAwesomeIcon icon={faExclamationTriangle} />} style={{width: "50%", marginTop: "100px"}}>
                <h2 style={{marginTop: 0}}>Seleccione una conversación</h2>
                <p>
                    Elegila en la barra lateral o creá una nueva.
                </p>
            </Alert>
        </Box>

    )
}

export default Home;
