import React, { createContext, useState, ReactNode } from 'react';
import {Cuenta} from "types/Cuenta";
import {Asistente} from "../types/Asistente";
import {Conversacion} from "../types/Conversacion";
import {Usuario} from "../types/Usuario";

interface AppContextType {
    appBootstrapping: boolean;
    setAppBootstrapping: (appBootstrapping: boolean) => void;
    accountConfig: any;
    setAccountConfig: (accountConfig: any) => void;
    errorSistema: string;
    setErrorSistema: (errorSistema: string) => void;
    appEmbebida: boolean;
    setAppEmbebida: (appEmbebida: boolean) => void;
    tokenUsuario: string;
    setTokenUsuario: (tokenUsuario: string) => void;
    tokenSistema: string;
    setTokenSistema: (tokenSistema: string) => void;
    tokenSistemaValido: boolean;
    setTokenSistemaValido: (tokenSistemaValido: boolean) => void;
    apiAccesible: boolean;
    setApiAccesible: (apiAccesible: boolean) => void;
    cuentas: Cuenta[];
    setCuentas: (cuentas: Cuenta[]) => void;
    cuentaSeleccionada: Cuenta; 
    setCuentaSeleccionada: (cuenta: any) => void; 
    asistenteSeleccionado: Asistente; 
    setAsistenteSeleccionado: (asistente: Asistente) => void; 
    conversaciones: any[]; 
    setConversaciones: (conversaciones: Conversacion[]) => void; 
    conversacionSeleccionada: any; 
    setConversacionSeleccionada: (conversacion: any) => void; 
    usuario: Usuario; 
    setUsuario: (usuario: Usuario) => void;
    timezone: string;
    setTimezone: (timezone: string) => void;
    idConversacion: string;
    setIdConversacion: (idConversacion: string) => void;
    debug: boolean;
    setDebug: (debug: boolean) => void;
    debugInfo: any;
    setDebugInfo: (debugInfo: any) => void;
}

export const AppContext = createContext<AppContextType | undefined>(undefined);

interface AppProviderProps {
    children: ReactNode;
}

/**
 * Proveedor de contexto de la aplicación
 *
 * @param children
 * @constructor
 */
export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {

    // gestiona el loading de la app
    const [appBootstrapping, setAppBootstrapping] = useState<boolean>(true);
    const [debug, setDebug] = useState<boolean>(true);
    const [errorSistema, setErrorSistema] = useState<string|null>(null);
    const [idConversacion, setIdConversacion] = useState(null);
    const [debugInfo, setDebugInfo] = useState<any>({"app": {}, "cuenta": {}, "conversacion": {}, "usuario": {}});

    // tipo de app
    const [appEmbebida, setAppEmbebida] = useState<boolean>(false);
    const [timezone, setTimezone] = useState<string>("America/Argentina/Buenos_Aires");

    // variable que gestiona si la api esta accesible o no
    const [apiAccesible, setApiAccesible] = useState<boolean>(false);

    // token usuario externo. lo envia la app frontend al react para inicializarse
    const [tokenUsuario, setTokenUsuario] = useState<string>("");

    // token JWT de sistema generado por nuestro backend post validacion token externo
    const [tokenSistema, setTokenSistema] = useState<string>(""); // Añade esta línea

    // seteo el token sistema valido
    const [tokenSistemaValido, setTokenSistemaValido] = useState<boolean>(null)
    const [accountConfig, setAccountConfig] = useState<any>(null)

    // variable que guarda el usuario
    const [usuario, setUsuario] = useState<Usuario>(); // Añade esta línea

    // gestion de las cuentas
    const [cuentas, setCuentas] = useState<Cuenta[]>([]);
    const [cuentaSeleccionada, setCuentaSeleccionada] = useState<Cuenta>();
    const [asistenteSeleccionado, setAsistenteSeleccionado] = useState<Asistente>();

    // conversaciones
    const [conversaciones, setConversaciones] = useState<Conversacion[]>([]);
    const [conversacionSeleccionada, setConversacionSeleccionada] = useState<Conversacion>();

    const value = {
        appBootstrapping, setAppBootstrapping,
        appEmbebida, setAppEmbebida,
        tokenUsuario, setTokenUsuario,
        tokenSistema, setTokenSistema,
        tokenSistemaValido, setTokenSistemaValido,
        apiAccesible, setApiAccesible,
        cuentas, setCuentas,
        cuentaSeleccionada, setCuentaSeleccionada,
        conversaciones, setConversaciones,
        conversacionSeleccionada, setConversacionSeleccionada,
        asistenteSeleccionado, setAsistenteSeleccionado,
        usuario, setUsuario,
        errorSistema, setErrorSistema,
        timezone, setTimezone,
        idConversacion, setIdConversacion,
        accountConfig, setAccountConfig,
        debug, setDebug,
        debugInfo, setDebugInfo
    };

    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};