import {MessageDto} from "../models/MessageDto";
import {Box} from "@mui/material";
import MessagesPanel from "./MessagesPanel";
import React from "react";

interface ListProps {
    gruposDeMensajes?: any[];
    conversationId: string;
    asistenteSeleccionado: any;
    isWaiting: boolean;
}

/**
 * Componente que muestra la lista de mensajes cargada por el chat
 * @param props
 * @constructor
 */
const MessagesList: React.FC<ListProps> = (props) => {
    const {gruposDeMensajes, conversationId, asistenteSeleccionado, isWaiting} = props;
    if (!gruposDeMensajes) {
        return null;
    }
    return (
        <Box>
            {gruposDeMensajes.map((groupMessages, index) => {
                let lastGroup = index > 0 ? gruposDeMensajes[index - 1] : null;
                return (
                    <MessagesPanel
                        conversationId={conversationId}
                        messages={groupMessages}
                        lastGroup={lastGroup}
                        isBuildingPanel={false}
                        isWaiting={isWaiting}
                        assistant={asistenteSeleccionado}
                        key={"index-grouped-" + index}/>
                )
            })}
        </Box>
    )
}

export default MessagesList;