type Props = {
  variant?:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "caps"
    | "p1"
    | "p2"
    | "p3"
    | "p4";
  style?: React.CSSProperties;
  children?: React.ReactNode;
};

export const Text = ({ variant = "h1", style = {}, children }: Props) => {
  const fontStyle = { fontWeight: 500, color: "inherit", margin: 0, ...style };

  switch (variant) {
    case "h1":
      return (
        <h1
          style={{
            ...fontStyle,
            fontFamily: "Gotham Rounded",
            fontSize: "40px",
            lineHeight: "40px",
            letterSpacing: "-2%",
          }}
        >
          {children}
        </h1>
      );
    case "h2":
      return (
        <h2
          style={{
            ...fontStyle,
            fontFamily: "Gotham Rounded",
            fontSize: "36px",
            lineHeight: "36px",
            letterSpacing: "-2%",
          }}
        >
          {children}
        </h2>
      );
    case "h3":
      return (
        <h3
          style={{
            ...fontStyle,
            fontFamily: "Gotham Rounded",
            fontSize: "32px",
            lineHeight: "32px",
            letterSpacing: "-2%",
          }}
        >
          {children}
        </h3>
      );
    case "h4":
      return (
        <h4
          style={{
            ...fontStyle,
            fontFamily: "Gotham Rounded",
            fontSize: "24px",
            lineHeight: "24px",
            letterSpacing: "-2%",
          }}
        >
          {children}
        </h4>
      );
    case "h5":
      return (
        <h5
          style={{
            ...fontStyle,
            fontFamily: "Gotham Rounded",
            fontSize: "20px",
            lineHeight: "20px",
            letterSpacing: "-2%",
          }}
        >
          {children}
        </h5>
      );
    case "h6":
      return (
        <h6
          style={{
            ...fontStyle,
            fontFamily: "Gotham Rounded",
            fontSize: "14px",
            lineHeight: "14px",
            letterSpacing: "-2%",
          }}
        >
          {children}
        </h6>
      );
    case "caps":
      return (
        <p
          style={{
            ...fontStyle,
            fontFamily: "Gotham Rounded",
            fontSize: "12px",
            lineHeight: "125%",
            letterSpacing: "0",
          }}
        >
          {children}
        </p>
      );
    case "p1":
      return (
        <p
          style={{
            fontFamily: "Work Sans",
            fontSize: "20px",
            lineHeight: "31.2px",
            ...fontStyle,
          }}
        >
          {children}
        </p>
      );
    case "p2":
      return (
        <p
          style={{
            ...fontStyle,
            fontFamily: "Work Sans",
            fontSize: "18px",
            lineHeight: "26px",
          }}
        >
          {children}
        </p>
      );
    case "p3":
      return (
        <p
          style={{
            ...fontStyle,
            fontFamily: "Work Sans",
            fontSize: "16px",
            lineHeight: "20px",
          }}
        >
          {children}
        </p>
      );
    case "p4":
    default:
      return (
        <p
          style={{
            ...fontStyle,
            fontFamily: "Work Sans",
            fontSize: "13px",
            lineHeight: "16.25px",
          }}
        >
          {children}
        </p>
      );
  }
};
