import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { faRobot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppContext } from "../hooks/useAppContext";
import { useTheme } from "@mui/material/styles";
import { Text } from "./Text";

export default function ButtonAppBar() {
  const { accountConfig } = useAppContext();
  const theme = useTheme();

  return (
    <Box
      style={{
        position: "sticky",
        top: 0,
        width: "100%",
        backgroundColor: "white",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        paddingBottom: "10px",
      }}
    >
      <FontAwesomeIcon
        icon={faRobot}
        title={"#"}
        style={{
          height: "2.1em",
          width: "2.1em",
          padding: "0.2em 1.6em 0.2em 0.1em",
          color: theme.palette.primary.main,
        }}
      />
      <Text variant="h3" style={{ color: theme.palette.primary.main }}>
        {accountConfig?.headerTitle ? accountConfig.headerTitle : ""}
      </Text>
    </Box>
  );
}
