import {useCallback, useContext} from 'react';
import { useAppContext } from 'hooks/useAppContext';
import useFetchAPI from "./useFetchApi"; // Asume que ya tienes esta función

const useConversaciones = () => {
    const {
        cuentaSeleccionada,
        setApiAccesible,
        setConversaciones,
        conversacionSeleccionada
    } = useAppContext(); // Usar el contexto para acceder a la cuenta seleccionada y setters

    const fetchAPI = useFetchAPI(); // Asume que ya tienes esta función

    // Función para inicializar o actualizar las conversaciones
    const actualizarConversaciones = async (accountId = null) => {
        if (!accountId && cuentaSeleccionada) {
            accountId = cuentaSeleccionada.id;
        }
        if (!accountId) {
            console.error("No se ha proporcionado un accountId y no hay cuenta seleccionada.");
            return;
        }

        const response = await fetchAPI(`/conversations/list/${accountId}`, 'GET');
        if (!response) {
            setApiAccesible(false);
            return;
        }

        let data = response;
        if (!data["conversations"]) {
            setConversaciones([]);
        } else {
            setConversaciones(data["conversations"]);
        }
    };

    const actualizarTituloConversacion = async (conversacionId, titulo) => {
        const formData = new FormData();
        formData.append('titulo', titulo);

        let data = await fetchAPI(`/conversations/${conversacionId}/update-title`, 'POST', formData);

        if (!data) {
            console.error('Error al actualizar el título de la conversación');
            return false;
        }

        actualizarConversaciones();
    }

    const eliminarConversacion = useCallback(async (conversacionId : number = null) => {
        if (!conversacionId) {
            conversacionId = conversacionSeleccionada.id;
        }
        let data = await fetchAPI(`/conversations/${conversacionId}/delete`);

        if (!data) {
            console.error('Error al eliminar la conversación');
            return false;
        }

        actualizarConversaciones();
    }, [conversacionSeleccionada]);

    // Devolver la función para que pueda ser usada externamente
    return { actualizarConversaciones, eliminarConversacion, actualizarTituloConversacion };
};

export default useConversaciones;
