import React, {useEffect, useState} from 'react';
import {
    Drawer,
    List,
    ListItem,
    ListItemText,
    Grid,
    ListItemButton,
    ListItemSecondaryAction,
    Button,
    Container,
    Box,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    Table, TableBody, TableCell, TableContainer, TableRow, Paper
} from '@mui/material';
import {Cuenta} from "../types/Cuenta";
import useInicializadorApp from "../hooks/useInicializadorApp";
import Loader from "../components/Loader";

type AccountSelectionProps = {
    accounts: any[];
    selectedAccount: any;
    setSelectedAccount: (account: Cuenta) => void;
}
const AccountSelection = (props:AccountSelectionProps) => {
    const { accounts, selectedAccount, setSelectedAccount } = props;
    const {seleccionarCuenta} = useInicializadorApp();

    if (!accounts) return (<Loader/>);

    if (accounts.length > 1 ) {
        return (
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
                <Container maxWidth="md">
                    <Typography variant="h4" gutterBottom>Seleccionar cuenta</Typography>
                    <Typography variant="body1" gutterBottom>Por favor, selecciona una cuenta de la lista a continuación:</Typography>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableBody>
                                {accounts.map((account) => (
                                    <TableRow key={account.id}>
                                        <TableCell>{account.nombre}</TableCell>
                                        <TableCell align="right">
                                            <Button variant="contained" color="primary" onClick={() => seleccionarCuenta(account)}>
                                                Seleccionar
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Container>
            </Box>
        )
    }
    else{ 
        //console.log("No hay cuentas")
        return (<Loader/>)
    }
}

export default AccountSelection;