import {useCallback, useEffect} from 'react';
import {useAppContext} from 'hooks/useAppContext';
import {useLocation} from "react-router-dom";
//import fetchAPI from "../api";
import useFetchAPI from "hooks/useFetchApi";
import {Cuenta} from "../types/Cuenta";
import {Usuario} from "../types/Usuario"; // Asume que ya tienes esta función

/**
 * Hook para inicializar/bootstrapear la aplicación
 * Sus responsabilidades en orden son:
 *  - Chequear si hay un token de sistema en localStorage
 *  - Sino hay chequear si hay un token de usuario en la URL y autenticarlo
 *      - Si no hay token de usuario, mostrar un error
 *      - Validar el token de usuario contra la api de autenticacion
 *      - Guardar el token de usuario en localStorage si es valido sino mostrar erro
 *  - Si hay validar el token de sistema contra la api de autenticacion
 *      - Si el token no es válido mostrar error
 *  - Cargar los datos del usuario
 *  - Cargar las cuentas del usuario
 *  - Seleccionar una cuenta por defecto si hay una sola
 *  - Seleccionar una cuenta si hay una en la URL
 *  - Exponer funciones para levantar las cuentas y seleccionar una cuenta
 *
 * @returns {Object} - Objeto con las funciones de levantarCuentas y seleccionarCuenta
 */
const useInicializadorApp = () => {
    const location = useLocation();
    const {
        setAppBootstrapping,
        setApiAccesible,
        setTokenUsuario,
        errorSistema, setErrorSistema,
        tokenSistema, setTokenSistema,
        tokenSistemaValido,
        setTokenSistemaValido,
        setAppEmbebida,
        setAsistenteSeleccionado,
        setCuentas,
        setCuentaSeleccionada,
        usuario, setUsuario,
        apiAccesible,
        debug, setDebug,
        debugInfo, setDebugInfo,
        timezone, setTimezone,
        accountConfig, setAccountConfig
    } = useAppContext();

    const fetchAPI = useFetchAPI();

    const extraemosTokensUsuario = (): string => {
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get('token');
        return token;
    };

    const autenticarTokenUsuario = async (tokenUsuario: string): Promise<Object> => {
        const formData = new FormData();
        formData.append('token', tokenUsuario);
        let datos = null;

        try {
            datos = await fetchAPI("/auth/autenticar", "POST", formData, false)
        } catch (e) {
            setErrorSistema("Error al autenticar el token de usuario.");
            setAppBootstrapping(false);
        }

        return datos;
    }

    /**
     * Función hardcodeada para generar un token de usuario que en un futuro debe ser enviado via la app padre.
     *
     * @todo: Mover la lógica al módulo de agrology
     * @todo: Garantizar que esto es solo en dev sino tiene que recibbir el token de la app padre
     */
    const generarAuthToken = async () => {
        // console.log("llamo a generarAuthToken");
        //const url = process.env.REACT_APP_AUTH_URL; // URL de la API
        // const username = process.env.REACT_APP_AUTH_USERNAME; // Nombre de usuario para la API
        // const password = process.env.REACT_APP_AUTH_PASSWORD; // Contraseña para la API
        // const recaptchaResponse = process.env.REACT_APP_RECAPTCHA_RESPONSE; // Respuesta de reCAPTCHA

        const url = "https://dev.api.agrology.com/token_auth/";
        const username = "zedrolirti@gufum.com";
        const password = "Ringo150!"; // Contraseña para la API
        const recaptchaResponse = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGKhI";

        const payload = {
            username: username,
            password: password,
            "g-recaptcha-response": recaptchaResponse
        };

        try {
            let response = await fetchAPI(
                url,
                "POST",
                JSON.stringify(payload),
                false,
                {"Content-Type": "application/json"},
                false
            );

            if (response && response.token) {
                return response.token;
            } else {
                throw new Error('No se recibió token de la API');
            }
        } catch (error) {
            console.error("Error al generar el AuthToken:", error);
            setErrorSistema("Error al generar el AuthToken: " + error.message);
            return null;
        }
    };

    const chequeamosAppEmbebida = () => {
        const queryParams = new URLSearchParams(location.search);
        if (queryParams.get('embed') !== null) {
            setAppEmbebida(true);
        }
    };

    const extraerCuentaDeUrl = () => {
        // Obtén la ruta actual
        let _cuentaSeleccionada = null;
        const path = location.pathname;

        // Divide la ruta en segmentos
        const segments = path.split('/');

        // Verifica si el segundo segmento existe
        if (segments[1]) {
            return segments[1];
        }

        return null;
    }

    // Definir levantarCuentas fuera de useEffect
    const levantarCuentas = async (levantarDefault = false) => {
        let url = "/accounts";
        if (debug) {
            url = "/accounts?debug=1";
        }

        const response = await fetchAPI(url, 'GET');
        if (!response) {
            setErrorSistema("No hay cuentas disponibles para el usuario");
            return;
        }

        // @todo: validar cuentas
        let cuentasApi = response["accounts"];
        setCuentas(cuentasApi);

        if (debug) {
           setDebugInfo((debugInfo) => { return {...debugInfo, "app": response['debug']}});
        }

        if (levantarDefault) {
            let cuentaDeUrl = extraerCuentaDeUrl();
            if (cuentaDeUrl) {
                // Busca una cuenta que coincida con el segundo segmento
                const account = cuentasApi.find(account => account.nombre.toLowerCase() === cuentaDeUrl.toLowerCase());
                if (account) {
                    seleccionarCuenta(account);
                    return;
                }
            }

            if (cuentasApi.length === 1) {
                seleccionarCuenta(cuentasApi[0]);
                return;
            }
        }
    };

    // Definir levantarCuentas fuera de useEffect
    const obtenerDatosDeUsuario = async (): Promise<Usuario | null> => {
        try {
            let url = '/auth/obtener-info-usuario';
            if (debug) {
                url += '?debug=1';
            }

            const response = await fetchAPI(url, 'GET');
            if (!response) {
                return null;
            }

            let usuario = response["usuario"];
            let timezone = response["timezone"];
            setTimezone(timezone);

            if (debug) {
                setDebugInfo((debugInfo) => { return {...debugInfo, "usuario": response['debug']}});
            }

            return usuario;

        } catch (error) {
            console.error("An error occurred:", error.message);
            return null;
        }

    }

    const seleccionarCuenta = async (cuenta: Cuenta) => {
        const response = await fetchAPI(`/accounts/select/${cuenta.id}`, 'GET');
        if (!response) {
            setApiAccesible(false);
            return;
        }

        let data = response;
        if (data["status"] == "ok") {
            setCuentaSeleccionada(cuenta);
            setAsistenteSeleccionado(cuenta.assistant);
        } else {
            alert("Error al seleccionar la cuenta");
            setApiAccesible(false);
        }

        if (cuenta.assistant.js_module) {
            // console.log("asistente con js module", assistant.js_module);
            let js_module = cuenta.assistant.js_module
            // replace dots with slashes
            js_module = js_module.replace(/\./g, '/');

            import("../customizations/"+js_module+"/config.tsx").then((module) => {
                let config = module.default;
                console.log("cargo la configuracion");
                setAccountConfig(config);
            }).catch((e) => {
                setAccountConfig(null);
            });
        }
    }

    /**
     * useEffect para inicializar la aplicación
     * Se encarga de chequear si ya hay un token de sistema y sino lo carga via parametro
     */
    useEffect(() => {
        const environment = process.env.REACT_APP_ENVIRONMENT;
        let debug = environment == "production" ? false : true;
        setDebug(debug);

        const _autenticarTokenUsuario = async (tokenUsuario: string) => {
            let datosUsuarios = await autenticarTokenUsuario(tokenUsuario);
            return datosUsuarios;
        }

        const iniciarSesion = async () => {
            // Intenta obtener el token de localStorage primero
            let chatToken = window.localStorage.getItem("chat_token");
            let llmToken = window.localStorage.getItem("llm_token");

            const token = await extraemosTokensUsuario();

            // si tenemos token y es distinto al token que tenemos guardado regeneramos

            // si no tenemos llmToken y si token de usuario lo autenticamos
            if (token && token !== chatToken) {
                window.localStorage.removeItem("chat_token")
                window.localStorage.removeItem("llm_token")

                // si el token enviado es distinto al que tenemos en local storage regeneramos el token
                const datosUsuarios = await _autenticarTokenUsuario(token);
                if (!datosUsuarios || !datosUsuarios["token_jwt"]) {
                    setErrorSistema("El token de usuario no es válido");
                    setAppBootstrapping(false);
                    return;
                }

                // Si la autenticación es exitosa, actualiza el token en localStorage y estado
                llmToken = datosUsuarios["token_jwt"];
                window.localStorage.setItem("chat_token", token);
                window.localStorage.setItem("llm_token", llmToken);
            }

            // sino envia el token chequeamos que ya exista
            if (!llmToken) {
                // devuelvo un array donde el idx 0 es el token de agrology y el idx 1 es el token de chat generado desde el frontend también
                // lo pasamos al backend ya que sino por CORS no podemos usarlo luego corregirlo.
                // const tokenUsuario = await generarAuthToken();
                if (!token) {
                    setErrorSistema("Token faltante");
                    setAppBootstrapping(false);
                    return;
                }
            }

            setTokenSistema(llmToken);
        };

        iniciarSesion().catch(error => {
            console.error("Error durante la inicialización:", error);
            setErrorSistema("Error al iniciar sesión");
            setAppBootstrapping(false);
        });
    }, []);

    /**
     * useEffect para cargar los datos del usuario.
     * Espera que se haya seteado el token de sistema y ahí busca cargar los datos del usuario
     */
    useEffect(() => {
        if (!tokenSistema) {
            return;
        }

        // si ya cargue el usuario no vuelvo a llamar
        if (usuario) {
            return;
        }

        const _obtenerDatosDeUsuario = async (): Promise<Usuario> => {
            let datosUsuario = await obtenerDatosDeUsuario();
            return datosUsuario;
        }

        const inicializarUsuario = async () => {
            // Ahora obtén los datos del usuario con el nuevo token
            const datosUsuario = await _obtenerDatosDeUsuario(); // Asegúrate de que esta llamada también use await correctamente
            if (!datosUsuario) {
                setErrorSistema("El token de sistema no es válido");
                setAppBootstrapping(false);
                return;
            }

            // Actualizar el estado con los datos del usuario y finalizar la secuencia de arranque
            setUsuario(datosUsuario);
            setTokenSistemaValido(true);
            setAppBootstrapping(false);

            return datosUsuario;
        }

        inicializarUsuario().then((usuario) => {
            if (usuario) {
                levantarCuentas(true);
            }
        });
    }, [tokenSistema, usuario]);

    /**
     * useEffect para levantar las cuentas del usuario
     * Espera que se haya seteado el usuario y ahí busca cargar las cuentas
     */
    useEffect(() => {
        if (!usuario) {
            // console.log("Usuario no definido");
            return;
        }

        chequeamosAppEmbebida();
    }, [usuario]);

    // Exponer levantarCuentas para uso externo
    return {levantarCuentas, seleccionarCuenta};
};

export default useInicializadorApp;
