import React, {useEffect, useState} from 'react';
import { Box, Typography, Button, Grid, Paper } from '@mui/material';
import useInicializadorApp from "../hooks/useInicializadorApp";
import {useAppContext} from "../hooks/useAppContext";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";


type HeroPanelProps = {
    assistant: any;
    iniciarPregunta: CallableFunction;
}
const HeroPanel = (props:HeroPanelProps) => {
    const { assistant, iniciarPregunta } = props;
    const [heroPanel, setHeroPanel] = useState<any>(null);
    const mTheme = useTheme();
    const isMobile = useMediaQuery(mTheme.breakpoints.down('sm'));

    const {
        usuario
    } = useAppContext();


    useEffect(() => {
        if (assistant.js_module) {
            // console.log("asistente con js module", assistant.js_module);
            let js_module = assistant.js_module
            // replace dots with slashes
            js_module = js_module.replace(/\./g, '/');

            import("../customizations/"+js_module+"/config.tsx").then((module) => {
                let config = module.default;
                // console.log(config.texto);
                if (config.heroText) {
                    setHeroPanel(config.heroText(iniciarPregunta, usuario, isMobile));
                } else {
                    setHeroPanel(defaultHeroText);
                }

            }).catch((e) => {
                setHeroPanel(defaultHeroText)
            });
        } else {
            setHeroPanel(defaultHeroText);
        }
    }, [assistant]);




    return (
        <Paper variant={"outlined"} style={{padding: iniciarPregunta? '0px 0px': '40px 20px', margin: '20px auto', textAlign: 'left', 'border': "none", background: iniciarPregunta ? 'transparent' : 'white' }}>
            {heroPanel}
        </Paper>
    );
};

const defaultHeroText = (
    <Box>
        <Box padding={2}>
            <Typography variant="h4" gutterBottom>
                Bienvenido a School Assistant
            </Typography>
            <Typography variant="body1" gutterBottom>
                Soy un asistente inteligente diseñado para apoyar a estudiantes de postgrado y maestría. Aquí están mis responsabilidades y cómo las cumplo:
            </Typography>
            <Typography variant="body1" gutterBottom>
                1. Mi objetivo general es facilitar a los estudiantes el acceso a información académica esencial, proporcionar motivación y orientación en sus estudios, y ayudar en la organización de sus tareas y materiales de estudio.
            </Typography>
            <Typography variant="body1" gutterBottom>
                2. Mis funciones clave incluyen el acceso a información de cursada, acceso a información de alumnos, motivación y orientación en estudios, gestión de materiales de estudio, acceso a búsquedas en internet y ayuda en la creación de listas de tareas personalizadas.
            </Typography>
        </Box>
        <Grid container spacing={2} justifyContent="left" style={{ marginTop: '10px' }}>
            <Grid item>
                <Button variant="contained" color="primary">¿Cuál es la historia de EADA?</Button>
            </Grid>
            <Grid item>
                <Button variant="contained" color="primary">Qué materias tengo en la cursada?</Button>
            </Grid>
            <Grid item>
                <Button variant="contained" color="primary">Cuéntame de mis compañeros</Button>
            </Grid>
        </Grid>
    </Box>
)

export default HeroPanel;
