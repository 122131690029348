// En un nuevo archivo EmbedApp.tsx
import React, {useEffect, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import ChatIcon from '@mui/icons-material/Chat';
import MinimizedView from "./MinimizedView";
import Chat from "./components/Chat";
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {Routes, Route} from 'react-router-dom';
import {Box} from "@mui/material";
import AccountSelection from "./components/AccountSelection";
import {Cuenta} from "./types/Cuenta";
import {Conversacion} from "./types/Conversacion";
import {useAppContext} from "hooks/useAppContext";

interface EmbedAppProps {
    initConversations: () => void;
    setToolbarIcons: (toolbarIcons: any) => void;
    selectedAssistant: any;
    selectedAccount: any;
    selectAccount: (cuenta: Cuenta) => void;
    tema?: any;
    accounts?: any[];
}

const EmbedApp: React.FC<EmbedAppProps> = ({
                                               initConversations,
                                               selectedAssistant,
                                               selectedAccount,
                                               selectAccount,
                                               setToolbarIcons,
                                               tema,
                                               accounts
                                           }) => {

    const [isMinimized, setIsMinimized] = useState<boolean>(true);

    const { conversaciones, conversacionSeleccionada, setConversacionSeleccionada } = useAppContext();


    useEffect(() => {
        if (conversaciones && conversaciones.length > 0 && !conversacionSeleccionada) {
            //console.log("seteo:", conversaciones[conversaciones.length - 1]);
            setConversacionSeleccionada(conversaciones[conversaciones.length - 1]);
        }
    }, [conversaciones, conversacionSeleccionada]);

    const toggleMinimize = () => {
        setIsMinimized(!isMinimized);
    };

    return (
        <ThemeProvider theme={tema}>
            <div>
                <IconButton size={"large"} onClick={toggleMinimize}
                            style={{position: 'fixed', bottom: '20px', right: '20px', backgroundColor: "#efefef"}}>
                    <ChatIcon/>
                </IconButton>
                <Dialog open={!isMinimized} onClose={toggleMinimize} fullWidth maxWidth='sm'
                        style={{position: 'fixed', bottom: 0, right: 0, top: 'auto', left: 'auto'}}
                        BackdropProps={{style: {backgroundColor: 'transparent'}}}>


                    {selectedAccount ? (
                        <Box>
                            <DialogActions sx={{position:"absolute", right: 0, zIndex: 100, visibility: conversacionSeleccionada ? "visible" : "hidden"}}>
                                <IconButton edge="end" color="inherit" onClick={toggleMinimize} aria-label="close"
                                            style={{
                                                borderRadius: '50%',
                                                marginRight: "5px",
                                                backgroundColor: "#ececec"
                                            }}>
                                    <CloseIcon/>
                                </IconButton>
                            </DialogActions>
                            <DialogContent>
                                {/* Aquí puedes poner el contenido de tu chat */}
                                {conversacionSeleccionada && (
                                    <Chat actualizarSidebar={initConversations}
                                          setToolbarIcons={setToolbarIcons}
                                          conversacionId={conversacionSeleccionada.id}
                                          />

                                )}

                            </DialogContent>
                        </Box>
                    ) : (
                        <DialogContent>
                            <AccountSelection accounts={accounts} selectedAccount={selectedAccount}
                                              setSelectedAccount={selectAccount}/>
                        </DialogContent>
                    )}
                </Dialog>
            </div>
        </ThemeProvider>
    );
};

export default EmbedApp;