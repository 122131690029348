import React, {createContext, useContext, useState, ReactNode, useEffect, useCallback} from 'react';
import {AppContext} from "./AppContext";
import {MessageDto} from "../models/MessageDto";
import {useAppContext} from "../hooks/useAppContext";
import moment from 'moment-timezone';

interface ConversacionContextType {
    // listado de mensajes
    mensajes: any[]; 
    setMensajes: (mensaje: any) => void;
    agregarMensaje: (mensaje: any) => void;

    mensajesAgrupados: any[];
    setMensajesAgrupados: (mensaje: any) => void;

    // paginador
    ultimoMessageId: string | null;
    setUltimoMessageId: (ultimoMessageId: string | null) => void;
    hayMasPaginas: boolean;
    setHayMasPaginas: (hasMasPaginas: boolean) => void;
    cargoLista: boolean;
    setCargoLista: (cargoLista: boolean) => void;

    // loaders
    isLoading: boolean;
    setIsLoading: (isLoading: boolean) => void;
    isMessageWaiting: boolean;
    setIsMessageWaiting: (isMessageWaiting: boolean) => void;
    isWaiting: boolean;
    setIsWaiting: (isWaiting: boolean) => void;
    inputError: boolean;
    setInputError: (isWaiting: boolean) => void;

    // mensaje en construccion
    mensajeEnConstruccion: MessageDto;
    setMensajeEnConstruccion: (mensaje:MessageDto|null) => void;
    nuevoMensajeInput: string;
    setNuevoMensajeInput: (input: string) => void;
    nuevoMensajeArchivos: File[];
    setNuevoMensajeArchivos: (archivos: File[]) => void;
    nuevoMensajeToolCall: number;
    setNuevoMensajeToolCall: (nuevoMensajeToolCall: number) => void;

    nuevoMensajeRunStepError: boolean|string;
    setNuevoMensajeRunStepError: (nuevoMensajeRunStepError: boolean|string) => void;

    actualizarMensajeEnConstruccion: (value: any, full?: boolean) => void;
    actualizarArchivosMensajeEnConstruccion: (archivos: any) => void;

    // conversacion cerrada
    conversacionCerrada: boolean;
    setConversacionCerrada: (conversacionCerrada: boolean) => void;

    // input
    focus: boolean;
    setFocus: (focus: boolean) => void;

}

export const ConversacionContext = createContext<ConversacionContextType | undefined>(undefined);

export const ConversacionProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [mensajes, setMensajes] = useState<MessageDto[]>([]);
    const [mensajesAgrupados, setMensajesAgrupados] = useState<MessageDto[]>([]);
    const [mensajeEnConstruccion, setMensajeEnConstruccion] = useState<MessageDto|null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [hayMasPaginas, setHayMasPaginas] = useState(false);
    const [focus, setFocus] = useState(false);
    const [isMessageWaiting, setIsMessageWaiting] = useState<boolean>(true);
    const [ultimoMessageId, setUltimoMessageId] = useState<string>("");
    const [cargoLista, setCargoLista] = useState<boolean>(false);
    const [conversacionCerrada, setConversacionCerrada] = useState<boolean>(false);

    const [nuevoMensajeInput, setNuevoMensajeInput] = useState<string>("");
    const [nuevoMensajeArchivos, setNuevoMensajeArchivos] = useState<File[]>([]);

    const [isWaiting, setIsWaiting] = useState<boolean>(false);
    const [inputError, setInputError] = useState<boolean>(false);

    const {conversacionSeleccionada, setConversacionSeleccionada, timezone, asistenteSeleccionado} = useAppContext();

    /**
     * Guarda el estado del building tool (0: no está corriendo, 1: está corriendo, 2: terminó)
     */
    const [nuevoMensajeToolCall, setNuevoMensajeToolCall] = useState<number>(0);
    const [nuevoMensajeRunStepError, setNuevoMensajeRunStepError] = useState<boolean|string>(false);

    const actualizarMensajeEnConstruccion = (value, full:boolean = false) => {
        if (!value.data || value.data == "" || value.data == "undefined") return;
        
        setMensajeEnConstruccion((prevMessage) => prevMessage ? {
            ...prevMessage,
            content: !full ? prevMessage.content + value.data : value.data
        } : null);
    };

    const actualizarArchivosMensajeEnConstruccion = (archivos) => {
        setMensajeEnConstruccion((prevMessage) => prevMessage ? {
            ...prevMessage,
            files: archivos
        } : null);
    };

    useEffect(() => {
        //console.log("cambia la conversacion seleccionado", conversacionSeleccionada);
        if (!conversacionSeleccionada) {
            return;
        }

        if (conversacionSeleccionada.fecha_ultimo_mensaje == null) {
            setConversacionCerrada(false);
            return;
        }

        if (asistenteSeleccionado && asistenteSeleccionado.cierre_conversacion_segundos > 0) {
            const SEGUNDOS_PARA_QUE_CIERRE = asistenteSeleccionado.cierre_conversacion_segundos; // 15 minutos

            const fechaUltimoMensaje = moment.tz(conversacionSeleccionada.fecha_ultimo_mensaje, 'YYYY-MM-DD HH:mm:ss', timezone);
            const now = moment().tz(timezone);
            const diff = now.diff(fechaUltimoMensaje, 'seconds'); // Get the difference in seconds

            // console.log("fecha_ultimo_mensaje: ", conversacionSeleccionada.fecha_ultimo_mensaje);
            // console.log("timezone: ", timezone);
            // console.log("now: ", now.format("YYYY-MM-DD HH:mm:ss"));
            // console.log("diferencia: ", diff);

            if (diff > SEGUNDOS_PARA_QUE_CIERRE) {
                setConversacionCerrada(true);
            } else {
                setConversacionCerrada(false);
            }
        } else {
            setConversacionCerrada(false);
        }
    }, [conversacionSeleccionada, asistenteSeleccionado]);

    const agregarMensaje = (mensaje) => {
        setMensajes((prevMensajes) => [...prevMensajes, mensaje]);
    };


    const value = {
        mensajes, setMensajes,
        agregarMensaje,
        mensajesAgrupados, setMensajesAgrupados,
        isLoading, setIsLoading,
        mensajeEnConstruccion, setMensajeEnConstruccion,
        hayMasPaginas, setHayMasPaginas,
        isMessageWaiting, setIsMessageWaiting,
        ultimoMessageId, setUltimoMessageId,
        nuevoMensajeInput, setNuevoMensajeInput,
        nuevoMensajeArchivos, setNuevoMensajeArchivos,
        nuevoMensajeToolCall, setNuevoMensajeToolCall,
        nuevoMensajeRunStepError, setNuevoMensajeRunStepError,
        cargoLista, setCargoLista,
        isWaiting, setIsWaiting,
        inputError, setInputError,
        actualizarMensajeEnConstruccion, actualizarArchivosMensajeEnConstruccion,
        conversacionCerrada, setConversacionCerrada,
        focus, setFocus
    };

    return <ConversacionContext.Provider value={value}>{children}</ConversacionContext.Provider>;

};