import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  TextField,
  Button,
  Container,
  Grid,
  LinearProgress,
  CircularProgress,
  InputAdornment,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import AttachFile from "@mui/icons-material/AttachFile";
import SendIcon from "@mui/icons-material/Send";
import { useDropzone } from "react-dropzone";
import { useTheme } from "@mui/material/styles";
import { useConversacionContext } from "hooks/useConversacionContext";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Text } from "./Text";

type MessageComposerProps = {
  handleSendMessage: CallableFunction;
  isWaiting: boolean;
  isEmbed?: boolean;
  setIsWaiting: CallableFunction;
  assistant: any;
  inputError: boolean;
  nuevaConversacion: boolean;
};

const MessageComposer = (props: MessageComposerProps) => {
  const {
    handleSendMessage,
    isWaiting,
    setIsWaiting,
    isEmbed,
    assistant,
    inputError,
    nuevaConversacion,
  } = props;
  const theme = useTheme();
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    nuevoMensajeInput,
    setNuevoMensajeInput,
    nuevoMensajeArchivos,
    setNuevoMensajeArchivos,
    focus,
    setFocus,
    conversacionCerrada,
  } = useConversacionContext();

  const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Evita la creación de una nueva línea
      handleSendMessage();
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles, fileRejections, event) => {
      let prevFiles = nuevoMensajeArchivos;
      if (!prevFiles) {
        prevFiles = [];
      }

      setNuevoMensajeArchivos([
        ...prevFiles,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
    },
  });

  useEffect(() => {
    if (focus && inputRef.current) {
      inputRef.current.focus();
      setFocus(false);
    }
  }, [focus]);

  useEffect(() => {
    if (!isWaiting) {
      setTimeout(() => {
        setFocus(true);
      }, 1000);
    }
  }, [isWaiting]);

  useEffect(() => {
    setTimeout(() => {
      setFocus(true);
    }, 1000);
  }, []);

  return (
    <Box
      style={{
        backgroundColor: !nuevaConversacion ? "white" : "transparent",
        padding: !isEmbed ? "10px 0" : "10px 0 0",
      }}
    >
      <Grid container direction="row" justifyContent={"space-between"}>
        <Grid item sm={11} xs={9}>
          <TextareaAutosize
            minRows={1}
            ref={inputRef}
            style={{
              width: "99%",
              minHeight: "25px", // Altura mínima
              padding: "15px", // Padding
              borderRadius: "5px", // Bordes redondeados
              // Resto de los estilos...
              borderColor: inputError ? "red" : "#E3E3E3",
              animation: inputError
                ? "shake 0.82s cubic-bezier(.36,.07,.19,.97) both"
                : "none",

              borderWidth: "1px", // Ancho del borde
              borderStyle: "solid", // Estilo del borde
              lineHeight: "1.5", // Altura de línea
              fontFamily: "Work Sans",
              fontSize: "16px", // Tamaño de la fuente
            }}
            disabled={isWaiting}
            placeholder="Escribí tu mensaje..."
            value={nuevoMensajeInput}
            onChange={(e) => setNuevoMensajeInput(e.target.value)}
            onKeyDown={handleKeyPress}
          />
        </Grid>
        <Grid item sm={1} xs={2} textAlign={"right"}>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={() => {
              handleSendMessage();
            }}
            disabled={isWaiting}
            style={{
              minHeight: "3.2em", // Altura mínima
              minWidth: "unset", //Remover Propiedad de button
              borderRadius: "5px",
              background: theme.palette.primary.main,
              marginLeft: isMobile ? "10px" : "0",
            }}
          >
            <SendIcon
              sx={{
                background: theme.palette.primary.main,
                fontSize: "1.5rem",
                py: 1,
              }}
            />
          </Button>
        </Grid>
      </Grid>

      {assistant && assistant.upload_habilitado && (
        <Box>
          {isWaiting ? (
            <LinearProgress color="inherit" />
          ) : (
            <div
              {...getRootProps()}
              className={isDragActive ? "drag-zone drag-active" : "drag-zone"}
              onDragOver={(event) => {
                event.preventDefault();
              }}
              style={{ marginBottom: isMobile ? 0 : "20px" }}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <Text variant="p3">Suelta los archivos aquí ...</Text>
              ) : (
                <Text variant="p3">
                  Arrastra y suelta algunos archivos aquí, o haz clic para
                  seleccionar archivos
                </Text>
              )}

              {nuevoMensajeArchivos.length > 0 && (
                <ul>
                  {nuevoMensajeArchivos.map((file, index) => (
                    <li key={index}>
                      <Text variant="p3">{file.name}</Text>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}
        </Box>
      )}
    </Box>
  );
};

export default MessageComposer;
