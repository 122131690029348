// src/App.tsx
import React, { useEffect, useMemo, useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Grid,
  ListItemButton,
  ListItemSecondaryAction,
  Button,
  Container,
  Box,
  IconButton,
  AppBar,
  Toolbar,
  Typography,
  Backdrop,
  Modal,
  Fade,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  css,
  PaletteColor,
} from "@mui/material";
import Chat from "./components/Chat";
import Loader from "./components/Loader";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import Sidebar from "./components/Sidebar";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import Home from "./components/Home";
import MenuIcon from "@mui/icons-material/Menu";
import AccountSelection from "./components/AccountSelection";
import MinimizedView from "./MinimizedView";
import EmbedApp from "./EmbedApp";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import { useAppContext } from "hooks/useAppContext";
import { Cuenta } from "./types/Cuenta";
import { Asistente } from "./types/Asistente";
import { Conversacion } from "./types/Conversacion";
import useInicializadorApp from "./hooks/useInicializadorApp";
import useConversaciones from "./hooks/useConversaciones";
import PersonIcon from "@mui/icons-material/Person";
import { faRobot } from "@fortawesome/free-solid-svg-icons";
import "./App.css";
import deepmerge from "lodash/merge";
import DebugBar from "./components/DebugBar";
import { SimplePaletteColorOptions } from "@mui/material/styles/createPalette";
import baseTheme from "./theme";
import { Text } from "components/Text";

const App: React.FC = () => {
  const { seleccionarCuenta } = useInicializadorApp();
  const { actualizarConversaciones } = useConversaciones();

  // AppProvider
  const {
    appBootstrapping,
    errorSistema,
    appEmbebida,
    apiAccesible,
    setApiAccesible,
    cuentas,
    usuario,
    cuentaSeleccionada,
    setCuentaSeleccionada,
    asistenteSeleccionado,
    setAsistenteSeleccionado,
    conversaciones,
    setConversaciones,
    conversacionSeleccionada,
    setConversacionSeleccionada,
    tokenSistemaValido,
    accountConfig,
  } = useAppContext();

  const tema = useMemo(() => {
    if (accountConfig && accountConfig.theme) {
      let themeCompuesto = deepmerge(baseTheme, accountConfig.theme);
      return createTheme(themeCompuesto);
    }
    return createTheme(baseTheme);
  }, [accountConfig]);

  // layout vars
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const drawerWidth = 300;
  const [toolbarIcons, setToolbarIcons] = useState<
    Array<{ icon: React.ReactNode; onClick: () => void }>
  >([]);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const resetSelectedAccount = (navigate) => {
    setCuentaSeleccionada(null);
    setAsistenteSeleccionado(null);
    setConversaciones([]);
    setConversacionSeleccionada(null);
    navigate("/");
  };

  const selectAccount = async (cuenta: Cuenta) => {
    seleccionarCuenta(cuenta);
  };

  // Calcula el margen izquierdo del contenido principal en función de si el Sidebar está abierto y anclado a la izquierda
  const marginLeft = useMemo(() => {
    if (open && accountConfig.sidebar.anchor === "left") {
      return drawerWidth;
    } else {
      return 0;
    }
  }, [open, accountConfig]);

  // Calcula el margen derecho del contenido principal en función de si el Sidebar está abierto y anclado a la derecha
  const marginRight = useMemo(() => {
    if (open && accountConfig.sidebar.anchor === "right") {
      return drawerWidth;
    } else {
      return 0;
    }
  }, [open, accountConfig]);

  // una vez cargada la cuenta via hook levanto las conversaciones
  useEffect(() => {
    if (!usuario || !cuentaSeleccionada) {
      return;
    }

    actualizarConversaciones();
  }, [usuario, cuentaSeleccionada]);

  if (appBootstrapping) {
    return <Loader />;
  }

  if (errorSistema) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Alert
          severity="error"
          icon={
            <FontAwesomeIcon icon={faExclamationTriangle} fontSize="20px" />
          }
          style={{
            width: "50%",
            marginTop: "100px",
            border: "1px solid #d32f2f",
          }}
        >
          <Text variant="h5" style={{ marginBottom: "8px" }}>
            {errorSistema}
          </Text>
          <Text variant="p4">
            Lo sentimos por las molestias. <br />
            Por favor, inténtelo de nuevo más tarde o comuniquese con soporte.
          </Text>
        </Alert>
      </Box>
    );
  }

  if (!cuentas || (cuentas && cuentaSeleccionada && !conversaciones)) {
    return <Loader />;
  }

  if (!cuentaSeleccionada) {
    return (
      <AccountSelection
        accounts={cuentas}
        selectedAccount={cuentaSeleccionada}
        setSelectedAccount={selectAccount}
      />
    );
  }

  if (appEmbebida) {
    return (
      <EmbedApp
        initConversations={actualizarConversaciones}
        setToolbarIcons={setToolbarIcons}
        selectedAssistant={asistenteSeleccionado}
        selectedAccount={cuentaSeleccionada}
        selectAccount={selectAccount}
        accounts={cuentas}
        tema={tema}
      />
    );
  }

  return (
    //

    <ThemeProvider theme={tema}>
      <Box
        className="App"
        sx={{
          display: "flex",
          backgroundColor: tema.palette.background.default,
        }}
      >
        {/* <AppBar position="fixed" style={{backgroundColor: '#ffffff', borderBottom: 'none'}}
                        variant={"outlined"}>
                    <Toolbar style={{width: 'auto', marginLeft: '0vw', backgroundColor: '#292522'}}>
                        {isMobile && (
                            <IconButton
                                color="primary"
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                sx={{mr: 2, display: {sm: 'none'}}}
                            >
                                <MenuIcon/>
                            </IconButton>
                        )}

                        {conversacionSeleccionada && (
                            <Box display="flex" justifyContent="center" alignItems="center" width="100%"
                                 ml={0}>
                                <Box display="flex" justifyContent="center" alignItems="center" gap={1} width='80%' ml={isMobile ? 'none' : '10%'}>
                                    <img src={cuentaSeleccionada.logo ? "/images/" + cuentaSeleccionada.logo : "/images/growketing_logo.png"} style={{width: "10em", padding: "10px 0 ", margin: "0"}}/>
                                </Box>
                                {!isMobile && ( 
                                <Box width='20%' display='flex' justifyContent='right'>
                                    <Box style={{borderLeft: '2px #6b5f57 solid'}}>
                                        <Button style={{paddingLeft: '1.5em', marginTop: '-3px', marginBottom: '-3px', borderRadius: 'none!important', color: 'white',}} endIcon={<PersonIcon />}
                                        >
                                            <Typography pb={0} lineHeight={2} fontWeight='fontWeightMedium' textTransform='capitalize'>
                                                {usuario.nombre}
                                            </Typography>
                                        </Button>
                                    </Box>
                                </Box>
                                )}
                            </Box>
                        )}
                    </Toolbar>
                </AppBar> */}
        {/* <Container maxWidth='sm'></Container> */}
        <Sidebar
          seleccionarConversacion={(conversacion) => {
            setConversacionSeleccionada(conversacion);
            if (isMobile) {
              handleDrawerToggle();
            }
          }}
          anchor={"right"}
          open={open}
          handleDrawerToggle={handleDrawerToggle}
          resetSelectAccount={resetSelectedAccount}
          actualizarSidebar={actualizarConversaciones}
          mostrarSwitcher={cuentas.length > 1}
        />
        <main
          style={{
            flexGrow: 1,
            order:
              accountConfig?.sidebar && accountConfig.sidebar.anchor === "left"
                ? 2
                : 1,
          }}
        >
          <Grid container>
            <Routes>
              <Route path="/" element={<Home />} />

              <Route
                path="/nueva-conversacion"
                element={
                  <Chat
                    actualizarSidebar={actualizarConversaciones}
                    setToolbarIcons={setToolbarIcons}
                  />
                }
              />

              <Route
                path="/ver-conversacion/:conversationId"
                element={
                  <Chat
                    actualizarSidebar={actualizarConversaciones}
                    setToolbarIcons={setToolbarIcons}
                  />
                }
              />
            </Routes>
          </Grid>
        </main>
      </Box>

      <DebugBar />
    </ThemeProvider>
  );
};

export default App;
